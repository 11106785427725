<template>
	<div :class="isDesktop ? 'swiper-desktop-wrap' : 'swiper-mobile-wrap'">
		<p class="create-post-choose-title">Выбери превью для видео</p>
		<div class="relative" :style="stylePopUp">
			<div ref="swiper" class="create-post-preview swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="preview in previews" :key="`pr${preview.preview_id}`">
						<img :src="preview.url" class="w-full placeholder-bg" alt="" />
					</div>
				</div>
			</div>
			<div class="preview-list">
				<div
					:class="['preview-img-wrap', { 'preview-img-wrap-active': index === currentSlide }]"
					v-for="(preview, index) in previews"
					:key="`pr${preview.preview_id}`"
					@click="changeCurrentSlide(index)"
				>
					<img :src="preview.url" :class="['preview-img']" alt="" />
				</div>
			</div>
		</div>
		<button-component @click="onChoose" color="primary"> Выбрать </button-component>
	</div>
</template>

<script>
import { Swiper } from 'swiper'
import 'swiper/swiper.min.css'
import { mapState, mapActions } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'
import { useModalStore } from '@/stores/modal'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'ChooseCover',
	components: { ButtonComponent },
	data() {
		return {
			currentSlide: 0
		}
	},
	props: {
		isDesktop: {
			type: Boolean,
			default: false
		},
		videoPreview: {
			type: String,
			default: ''
		}
	},
	computed: {
		...mapState(useCreatePostStore, ['previews', 'preview_url']),
		...mapState(useModalStore, ['popUpSizeWidth', 'popUpSizeHeight']),
		stylePopUp() {
			if (document.documentElement.clientWidth > 980) {
				return {
					width: `${this.popUpSizeWidth - 416}px`,
					height: `${this.popUpSizeHeight - 88 - 48}px`
				}
			}
			return {
				width: null,
				height: null
			}
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['choosePreview']),
		changeCurrentSlide(index) {
			this.currentSlide = index
			this.$refs.swiper.swiper.slideTo(index)
		},
		initSwiper() {
			const swiper = new Swiper(this.$refs.swiper)
			swiper.on('slideChange', (e) => {
				this.currentSlide = e.activeIndex
			})
			swiper.slideTo(this.currentSlide)
		},
		onChoose() {
			this.$emit('close')
			this.choosePreview(this.currentSlide)
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.initSwiper()
			this.changeCurrentSlide(0)
		})
	}
}
</script>

<style lang="scss" scoped>
.swiper-desktop-wrap {
	& .btn {
		margin: 22px auto;
	}
	@media (min-width: $screen-desktop) {
		width: 100%;
		background-color: $color-black-additional-secondary;
		overflow: auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		& .btn {
			margin: 22px auto;
			width: 220px;
		}
		.swiper {
			height: 100%;
		}
	}
}
.create-post-choose-title {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 15px;
	text-align: center;
	color: $color-gray;
}

.create-post-preview {
	&.fix-scroll::after {
		/* Фикс скролла видео на iOS 17.1.1.-17.1.2. */
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}
}

.preview-list {
	display: flex;
	justify-content: center;
	@media (min-width: $screen-desktop) {
		position: absolute;
		z-index: 5;
		bottom: 0;
		width: 100%;
		left: 0;
		right: 0;
		background-color: rgba($color-black-total, 0.8);
		padding: 12px;
	}
}

.preview-img-wrap {
	position: relative;
	width: 46px;
	height: 61px;
	margin-bottom: 40px;
	margin-top: 40px;
	cursor: pointer;
	@media (min-width: $screen-desktop) {
		margin: 0;
	}
	&:hover {
		&::before {
			background-color: rgba($color-black-total, 0.2);
		}
	}
	&:active {
		&::before {
			background-color: rgba($color-black-total, 0.7);
		}
	}
}

.preview-img-wrap::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: $transition-opacity, $transition-background;
	background-color: rgba($color-black-total, 0.5);
}

.preview-img {
	width: 100%;
	height: 100%;
	width: 46px;
	transition:
		transform 0.6 ease-in-out,
		border 0.6 ease-in-out;
}

.preview-img-wrap:not(:last-child) {
	margin-right: 10px;
}

.preview-img-wrap-active::before {
	opacity: 0;
}

.preview-img-wrap-active .preview-img {
	transform: scale(1.2);
	border: 1.5px solid white;
	border-radius: 8px;
}
</style>
