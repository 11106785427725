<template>
	<div :class="isDesktop ? 'desktop-wrap' : 'mobile-wrap'">
		<div :class="['create-post-preview relative fix-scroll', { 'create-post-preview-horizontal': itsHorizontalVideo }]">
			<upload-image-preview :src="videoPreview" />
		</div>
		<div class="edit-post-btngroup">
			<button-component color="transparent" @click="$emit('changeVideo')"> Изменить Видео </button-component>
			<button-component color="primary" @click="$emit('close')">Сохранить</button-component>
		</div>
	</div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import UploadImagePreview from '@/components/UploadImagePreview.vue'

export default {
	name: 'EditVideo',
	components: { ButtonComponent, UploadImagePreview },
	props: {
		videoPreview: {
			type: String,
			default: ''
		},
		itsHorizontalVideo: {
			type: Boolean,
			default: false
		},
		isDesktop: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
.edit-video-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.edit-video-wrapper-dekstop {
	height: 100%;
	background-color: $color-black-additional-secondary;
	padding: 20px 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.edit-post-btngroup {
	padding-top: 20px;
	margin-top: auto;
	@media (min-width: $screen-desktop) {
		display: flex;
	}
	& .btn:first-child {
		margin-right: 13px;
	}
}

.edit-post-btngroup *:first-child {
	margin-bottom: 15px;
}
</style>
