<template>
	<div class="sidebar">
		<div
			v-for="(link, i) in isConsumer ? consumerLinks : isGirl ? girlLinks : guestLinks"
			:key="link.icon"
			:style="{ order: i }"
			@click="resetFilterAndScroll(link)"
		>
			<router-link
				:to="link.to"
				class="sidebar-item"
				:data-navigation-link="link.icon"
				:class="{ 'active-desktop': setActiveTab(link.to.name) }"
			>
				<svg-icon :name="link.icon" size="22px" />
				<p>{{ link.title }}</p>
				<notification-sidebar
					v-if="link.showNotification || (isPrivatesEnabled && link.icon === 'chat' && unreadThreadsCount)"
					:count="unreadThreadsCount"
				/>
			</router-link>
		</div>
		<div class="sidebar-links-group">
			<router-link v-if="menuLinks.includes('terms-of-service')" :to="{ name: 'eula' }">
				Пользовательское соглашение
			</router-link>
			<router-link v-if="menuLinks.includes('privacy-policy')" :to="{ name: 'privacy-policy' }">
				Политика конфиденциальности
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useHomeStore } from '@/stores/home'
import { useCreatePostStore } from '@/stores/createPost'
import SvgIcon from '@/components/SvgIcon.vue'
import { useMessengerStore } from '@/stores/messenger'
import NotificationSidebar from '@/components/desktop/NotificationSidebar.vue'
import Vue from 'vue'

export default {
	name: 'Sidebar',
	components: {
		SvgIcon,
		NotificationSidebar
	},
	computed: {
		commonRoutes() {
			const routes = [
				{
					to: { name: 'home' },
					icon: 'home',
					title: 'Лента'
				},
				{
					to: { name: 'search' },
					icon: 'search',
					title: 'Поиск'
				}
			]

			const chatRoute = {
				to: { name: 'chat' },
				icon: 'chat',
				title: 'Сообщения'
			}

			return this.isPrivatesEnabled ? [...routes, chatRoute] : routes
		},
		...mapState(useMessengerStore, {
			unreadThreadsCount: 'unreadThreadsCount'
		}),
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		isGuest() {
			return JSON.parse(Vue.auth.remember())?.role === 'guest'
		},
		...mapState(useGirlProfileStore, { girlNotifications: 'notifications' }),
		...mapState(useProfileStore, {
			consumerId: 'id',
			consumerNotifications: 'notifications',
			consumerNickname: 'nickname',
			consumerPrivates: 'show_privates'
		}),
		...mapState(useGirlProfileStore, {
			girlId: 'id',
			girlNickname: 'nickname',
			verification_status: 'verification_status',
			nickname_approved: 'nickname_approved',
			girlPrivates: 'show_privates'
		}),
		...mapState(useCreatePostStore, ['post']),
		consumerLinks() {
			return this.consumerId
				? [
						...this.commonRoutes,
						{
							to: { name: 'consumer-notifications' },
							icon: 'notifications',
							title: 'Уведомления',
							activeClass: 'active'
						},
						{
							to: {
								name: 'consumer-profile',
								params: { id: this.consumerId, nickname: this.consumerNickname }
							},
							icon: 'profile',
							title: 'Профиль',
							activeClass: 'active'
						}
					]
				: false
		},
		girlLinks() {
			return this.girlId
				? [
						...this.commonRoutes,
						{
							to: { name: 'author-notifications' },
							icon: 'notifications',
							title: 'Уведомления',
							activeClass: 'active'
						},
						{
							to: this.getAuthorProfile(),
							icon: 'profile',
							title: 'Профиль',
							activeClass: 'active',
							showNotification: this.verification_status !== 'approved'
						},
						{
							to: { name: 'author-schedule' },
							icon: 'schedule',
							title: 'Календарь',
							activeClass: 'active'
						}
					]
				: false
		},
		guestLinks() {
			return [
				...this.commonRoutes,
				{
					to: { name: 'login' },
					icon: 'notifications',
					title: 'Уведомления'
				},
				{
					to: { name: 'login' },
					icon: 'profile',
					title: 'Профиль'
				}
			]
		},
		menuLinks() {
			return this.$config.menuList
		},
		isPrivatesEnabled() {
			return this.girlPrivates || this.consumerPrivates || this.isGuest
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['openCreatePostDialog', 'hideValidationDialog']),
		...mapActions(useHomeStore, ['resetFilter']),
		getAuthorProfile() {
			if (!this.nickname_approved || this.verification_status === 'needs') {
				return { name: 'author-profile-id', params: { id: this.girlId } }
			}
			return { name: 'author-profile', params: { nickname: this.girlNickname, id: this.girlId } }
		},
		resetFilterAndScroll(link) {
			if (link.to.name === 'home') {
				this.resetFilter()
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})
			}
		},
		setActiveTab(tabName) {
			const profileLinks = ['author-profile-schedule', 'author-reactions', 'author-profile-edit']
			if (tabName === 'author-profile' && profileLinks.includes(this.$route.name)) {
				return true
			}
			return this.$route.name === tabName
		}
	}
}
</script>

<style lang="scss" scoped>
.sidebar {
	position: fixed;
	left: calc(208px - $content-desktop);
	right: 0;
	margin: 0 auto;
	width: 208px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: $color-gray;
	@media (max-width: $screen-desktop) {
		display: none;
	}
	&-links-group {
		display: flex;
		flex-direction: column;
		padding-top: 9px;
		padding-left: 10px;
		& a {
			font-weight: 600;
			font-size: 12px;
			color: rgba($color-gray, 0.5);
			transition: $transition-color;
			&:hover {
				color: $color-gray-hover;
			}
			&:active {
				color: $color-gray-active;
			}
		}
		& a:first-child {
			margin-bottom: 4px;
		}
	}
	&-item {
		height: 36px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 3px 10px 5px;
		margin-bottom: 7px;
		border-radius: 9px;
		position: relative;
		transition: $transition-color, $transition-background;
		& svg {
			width: 22px;
			height: 22px;
			min-height: 22px;
			min-width: 22px;
			margin-right: 14px;
			display: flex;
			flex-direction: row;
			justify-items: center;
			align-items: center;
			background-size: contain !important;
			background-position: center center !important;
			background-repeat: no-repeat !important;
		}
		&.active-desktop {
			color: $color-white;
			background-color: $color-black-secondary;
			cursor: default;
		}
		&:not(.active-desktop):hover {
			color: $color-gray-hover;
			background-color: $color-black-secondary-hover;
		}
		&:not(.active-desktop):active {
			color: $color-gray-active;
			background-color: $color-black-secondary-active;
		}
	}
}
</style>
