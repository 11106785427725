import { defineStore } from 'pinia'
import { useCommonStore } from '@/stores/common'
import { getNextPage } from '@/stores/helper'
import { useConsumersListsApiStore } from './api'

export const useConsumersListStore = defineStore('consumersListStore', {
	state: () => ({
		apiStore: useConsumersListsApiStore(), // import стора api
		commonStore: useCommonStore(), // import стора common
		isListsInit: false, // Флаг инициализации списков при первом запросе true/false
		consumersLists: [], // Основные списки пользователей
		consumersListsMeta: null, // Метаданные списков пользователей
		isInverseSelection: false, // Флаг инверсии выбора участников списка (добавить всех кроме выбранных)
		consumersToBeSaved: [], // Список пользователей, который будет добавляться, нужно очищать после каждого асинхронного действия или перед закрытием списка
		consumerToAdd: null, // Потребитель которого нужно будет добавить в выбранные списки (listsToSave)
		listsToSave: [], // Списки в которые нужно добавить потребителя (consumerToAdd)
		consumers: [], // Список пользователей для добавления в новый/имеющийся список
		consumersMeta: null, // Метаданные пользователей
		newListName: '' // Название списка
	}),
	getters: {
		getConsumersListById(state) {
			return (id) => state.consumersLists.find((e) => e.id === id)
		},
		getConsumersByListId(state) {
			return (id) => state.consumersLists.find((e) => e.id === id)?.consumers
		},
		getConsumersToBeSavedIds(state) {
			return () => state.consumersToBeSaved.map((e) => e.id)
		},
		hasConsumersToBeSaved(state) {
			return () => state.consumersToBeSaved.length > 0
		},
		getListsToSaveIds(state) {
			return () => state.listsToSave.map((l) => l.id)
		}
	},
	actions: {
		// state mutations
		setSelection(boolean) {
			this.isInverseSelection = boolean
		},
		setConsumersLists(lists) {
			this.consumersLists = lists
		},
		setConsumersListsMeta(meta) {
			this.consumersListsMeta = meta
		},
		addListToStore(list) {
			this.consumersLists.push(list)
		},
		updateStoredListData(list) {
			const idx = this.consumersLists.findIndex((l) => l.id === list.id)
			if (idx > -1) {
				this.consumersLists.splice(idx, 1, list)
			}
		},
		removeListFromStore(id) {
			this.consumersLists = this.consumersLists.filter((e) => e.id !== id)
		},
		setInitState(state) {
			this.isListsInit = state
		},
		setConsumersToBeSaved(array) {
			this.consumersToBeSaved = array
		},
		clearConsumersLists() {
			this.consumersLists = []
			this.consumersListsMeta = null
		},
		clearConsumersToBeSaved() {
			this.consumersToBeSaved = []
		},
		setConsumers(data) {
			this.consumers = data
		},
		setConsumersMeta(meta) {
			this.consumersMeta = meta
		},
		clearConsumers() {
			this.consumers = []
			this.consumersMeta = null
		},
		setConsumerToAdd(user) {
			this.consumerToAdd = user
		},
		clearConsumerToAdd() {
			this.consumerToAdd = null
			this.listsToSave = []
		},
		selectUser(user) {
			const userIndex = this.consumersToBeSaved.findIndex((u) => u.id === user.id)
			if (userIndex === -1) {
				this.consumersToBeSaved.splice(0, 0, user)
			} else {
				this.consumersToBeSaved.splice(userIndex, 1)
			}
		},
		selectList(list) {
			const listIndex = this.listsToSave.findIndex((l) => l.id === list.id)
			if (listIndex === -1) {
				this.listsToSave.splice(0, 0, list)
			} else {
				this.listsToSave.splice(listIndex, 1)
			}
		},
		selectLastList() {
			this.listsToSave.push(this.consumersLists[this.consumersLists.length - 1])
		},
		setNewListName(name) {
			this.newListName = name
		},

		// async  operations
		async initConsumersLists() {
			try {
				if (!this.isListsInit) {
					this.commonStore.setIsLoadingState(true) // Глобальный isLoading state
					const { data: lists, meta } = await this.apiStore.fetchAll()
					this.setConsumersLists(lists)
					this.setConsumersListsMeta(meta)
					this.setInitState(true)
				}
			} catch (e) {
				throw new Error(e.message)
			} finally {
				this.commonStore.setIsLoadingState(false)
			}
		},
		async addNewConsumersList(data) {
			try {
				this.commonStore.setIsLoadingState(true)
				const { data: list } = await this.apiStore.createOne(data)
				this.addListToStore(list)
			} catch (e) {
				throw new Error(e.message)
			} finally {
				this.commonStore.setIsLoadingState(false)
			}
		},
		async updateConsumersList(list) {
			if (this.isInverseSelection) {
				list.add_all_search_users = this.isInverseSelection
				list.exclude_consumers = this.getConsumersToBeSavedIds()
			}

			try {
				this.commonStore.setIsLoadingState(true)
				const { id } = list
				const { data: newList } = await this.apiStore.updateOne(id, list)
				this.updateStoredListData(newList)
			} catch (e) {
				throw new Error(e.message)
			} finally {
				this.commonStore.setIsLoadingState(false)
			}
		},
		async removeConsumersList(id) {
			try {
				this.commonStore.setIsLoadingState(true)
				await this.apiStore.deleteOne(id)
				this.removeListFromStore(id)
			} catch (e) {
				throw new Error(e.message)
			} finally {
				this.commonStore.setIsLoadingState(false)
			}
		},
		async getConsumers(params = {}) {
			try {
				this.commonStore.setIsLoadingState(true)
				const { data: consumers, meta } = await this.apiStore.getConsumers(params)
				this.setConsumers(consumers)
				this.setConsumersMeta(meta)
			} catch (e) {
				throw new Error(e.message)
			} finally {
				this.commonStore.setIsLoadingState(false)
			}
		},
		async getMoreConsumersLists() {
			const nextPage = getNextPage(this.consumersListsMeta)
			if (!nextPage) return
			try {
				const { data, meta } = await this.apiStore.fetchAll({ page: nextPage })
				this.setConsumersLists([...this.consumersLists, ...data])
				this.setConsumersListsMeta(meta)
			} catch (e) {
				throw new Error(e.message)
			}
		},
		async getMoreConsumers() {
			const nextPage = getNextPage(this.consumersMeta)
			if (!nextPage) return
			try {
				const { data, meta } = await this.apiStore.getConsumers({ page: nextPage })
				this.setConsumers([...this.consumers, ...data])
				this.setConsumersMeta(meta)
			} catch (e) {
				throw new Error(e.message)
			}
		},
		async addConsumerToList() {
			try {
				this.commonStore.setIsLoadingState(true)
				const { data } = await this.apiStore.addConsumerToList(this.consumerToAdd.id, this.getListsToSaveIds())
				data.forEach((list) => this.updateStoredListData(list))
			} catch (e) {
				throw new Error(e.message)
			} finally {
				this.commonStore.setIsLoadingState(false)
			}
		}
	}
})
