<template>
	<div class="create-post-fieldwrap">
		<div
			:class="['create-post-field', { 'create-post-field--disable': audienceFieldDisable }]"
			@click="openAudienceModal"
		>
			<p>
				<svg-icon name="privation" size="18px" />
				<span>Приватность</span>
			</p>
			<span class="color-red">{{ hasAttachedMedia ? postAudience : '' }}</span>
			<svg-icon name="chevron" size="22px" />
		</div>
		<div v-if="scheduledPostsEnabled">
			<div :class="['create-post-field', { 'create-post-field--disable': scheduledFieldDisable }]">
				<p>
					<svg-icon name="calendar" size="20px" />
					<span @click="openCalendar">
						<span v-if="!datePublic">Дата и время публикации</span>
						<span v-else>Публикация</span>
					</span>
				</p>
				<span @click="openCalendar" class="color-red">{{ datePublic }}</span>
				<span v-if="datePublic" class="create-post-field--cross i-cross" @click="removeDatePublic" />
				<svg-icon v-else name="chevron" size="22px" />
			</div>
			<div
				:class="['create-post-field', { 'create-post-field--disable': notificationFieldDisable }]"
				@click="changeNotification"
			>
				<p>
					<svg-icon name="notifications-stroke" size="20px" />
					<span> Уведомлять о выходе поста </span>
				</p>
				<span :class="['switcher', { 'switcher--on': getNotification }]" />
			</div>
		</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
	name: 'PostFieldsGroup',
	components: {
		SvgIcon
	},
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		hasAttachedMedia: {
			type: Boolean,
			default: false
		},
		canBeScheduled: {
			type: Boolean,
			default: false
		},
		datePublic: {
			type: String,
			default: ''
		},
		postAudience: {
			type: String,
			default: ''
		},
		getNotification: {
			type: Boolean,
			default: false
		},
		scheduledPostsEnabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		audienceFieldDisable() {
			return !this.hasAttachedMedia || this.showModal
		},
		scheduledFieldDisable() {
			return !this.canBeScheduled || !this.hasAttachedMedia || this.showModal
		},
		notificationFieldDisable() {
			return !this.datePublic || !this.canBeScheduled || this.showModal
		}
	},
	methods: {
		openAudienceModal() {
			if (!this.audienceFieldDisable) {
				this.$emit('openAudienceModal')
			}
		},
		openCalendar() {
			if (!this.scheduledFieldDisable) {
				this.$emit('openCalendar')
			}
		},
		changeNotification() {
			if (!this.notificationFieldDisable) {
				this.$emit('changeNotification')
			}
		},
		removeDatePublic() {
			if (!this.scheduledFieldDisable) {
				this.$emit('removeDatePublic')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.create-post-field {
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	color: $color-gray;
	justify-content: space-between;
	cursor: pointer;
	align-items: center;
	transition: $transition-color;
	&:not(.create-post-field--disable):hover {
		color: $color-white;
		& .switcher::before {
			background-color: $color-white;
		}
	}
	& p:first-child {
		flex-grow: 1;
		display: flex;
		align-items: center;
		& .svg-icon {
			margin-right: 10px;
		}
	}
}

.create-post-fieldwrap > div > .create-post-field:first-child {
	border-top: 1px solid rgba($color-black-additional-third, 0.5);
	border-bottom: 1px solid rgba($color-black-additional-third, 0.5);
}

.color-red {
	color: $color-magenta-secondary;
}

.create-post-field--disable {
	cursor: default;
	opacity: 30%;
}

.switcher {
	display: flex;
	height: 20px;
	width: 37px;
	background-color: rgba($color-gray-additional, 0.5);
	border-radius: 14px;
	transition: $transition-background;
	margin-right: 5px;
}

.switcher::before {
	content: '';
	width: 20px;
	height: 20px;
	border-radius: 14px;
	background-color: $color-gray;
	transition: $transition-transform, $transition-background;
}

.switcher--on {
	background-color: rgba($color-magenta-secondary, 0.5);
}

.switcher--on::before {
	transform: translateX(20px);
}

.create-post-field--cross {
	color: $color-gray-additional;
	font-size: 24px;
	margin-left: 5px;
}
</style>
