<template>
	<validation-provider tag="div" class="form-control" v-slot="{ errors, invalid, validated }" :name="id" :rules="rules">
		<label v-if="label" class="form-label" :for="id">{{ label }}</label>
		<div class="form-field-wrap">
			<input
				v-bind="$attrs"
				class="form-field"
				:class="{ invalid: invalid && validated }"
				:placeholder="placeholder"
				:value="value"
				:id="id"
				@input="$emit('input', $event.target.value)"
				@focus="$emit('focus')"
			/>
			<slot name="append" />
		</div>
		<validation-message :messages="errors" />
	</validation-provider>
</template>

<script>
import ValidationMessage from '@/components/forms/ValidationMessage.vue'
import { ValidationProvider } from 'vee-validate'

export default {
	name: 'InputComponent',
	inheritAttrs: false,
	components: { ValidationMessage, ValidationProvider },
	props: {
		value: { type: [String, Number], default: '' },
		id: { type: String, required: true },
		label: { type: String, default: '' },
		placeholder: { type: [String, Number], default: '' },
		rules: { type: Object, default: () => {} }
	}
}
</script>

<style lang="scss" scoped>
#search-input {
	width: 100%;
	display: block;
	padding: 8px 36px;
	min-height: 40px;
}
.form-auth {
	& .form-field::placeholder {
		@media (min-width: $screen-desktop) {
			color: $color-gray;
		}
	}
}
</style>
