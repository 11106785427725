import { http } from '@/http/index'

async function consumersWithLikes(postId) {
	return http.get(`/posts/${postId}/consumers-with-likes`).then((response) => response.data)
}

async function getMoreConsumersWithLikes(url) {
	return http.get(url).then((response) => response.data)
}

export { consumersWithLikes, getMoreConsumersWithLikes }
