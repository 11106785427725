import { http } from '@/http/index'

async function getRecommendedPost() {
	return http.get('/posts/recommended').then((response) => response.data)
}

async function getMoreRecommendedPost(url) {
	return http.get(url).then((response) => response.data)
}

export { getRecommendedPost, getMoreRecommendedPost }
