import { http } from '@/http/index'

const typeMap = {
	image: 'images',
	video: 'videos',
	audio: 'audios'
}

async function uploadPaidMedia(mediaType, messageId, formData, signal) {
	return http
		.post(`/paid-messages/${messageId}/${typeMap[mediaType]}`, formData, {
			signal
		})
		.then((response) => response.data)
}

async function deleteMedia(messageId, attachmentId) {
	return http.delete(`/paid-messages/${messageId}/attachments/${attachmentId}`)
}

export { uploadPaidMedia, deleteMedia }
