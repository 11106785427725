export const createButtonTypes = {
	default: 'default',
	loading: 'loading',
	ready: 'ready'
}

export const complaintToModeratorText = 'Модераторы рассмотрят ваше обращение в течение 24 часов'

export const subscriptionsParams = {
	likesForSubscription: 3,
	defaultSubscriptionsCount: 1
}

export const errorParams = {
	403: {
		message: 'Сервис недоступен. Попробуйте еще раз поздее, если ошибка повторится — обратитесь в службу поддержки.',
		button: {
			action: 'mailToSupport',
			text: 'Написать в поддержку'
		}
	}
}
