<template>
	<label
		@dragover.prevent="dragProcess = true"
		@dragleave.prevent="dragProcess = false"
		@drop="dragProcess = false"
		for="media"
		ref="inputArea"
		:class="['input-media-wrap', { 'active-drag': dragProcess }]"
	>
		<div class="input-media">
			<div v-if="heicProccess" class="pagination-loader">
				<i class="i-spinner rotating" />
			</div>
			<div v-else class="input-media-content">
				<svg-icon name="download" size="64px" />
				<p class="input-media-text"><span class="input-media-text-white">Выберите файл</span> или перетащите сюда</p>
			</div>
		</div>
		<input-media :hidden-input="false" ref="mediaInput" :rules="{ minDimensions: [1000, 217] }" />
	</label>
</template>

<script>
import { mapState } from 'pinia'
import InputMedia from '@/components/create-post/InputMedia.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { useCreatePostStore } from '@/stores/createPost'

export default {
	name: 'InputMediaDesktop',
	components: { InputMedia, SvgIcon },
	data() {
		return {
			dragProcess: false
		}
	},
	computed: {
		...mapState(useCreatePostStore, ['heicProccess'])
	}
}
</script>

<style lang="scss" scoped>
.input-media-wrap {
	width: 100%;
	min-width: 480px;
	height: 100%;
	background-color: $color-black-additional-secondary;
	margin-right: 24px;
	padding: 10px;
	transition: background-color 0.3s ease-in-out;
	position: relative;
	cursor: pointer;
	@media (max-width: $screen-desktop) {
		height: 200px;
		border-radius: 8px;
		min-width: unset;
		margin-bottom: 24px;
	}
	& .input-media {
		border: 1px dashed rgba($color-white, 0.1);
		width: 100%;
		height: 100%;
		border-radius: 1px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: rgba($color-white, 0.1);
		cursor: pointer;
	}
	.input-media-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	& .input-image::v-deep {
		position: absolute;
		opacity: 0;
		height: 100%;
		width: 100%;
		& input {
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
	&:hover {
		& .input-media-text-white {
			color: rgba($color-white, 0.1);
		}
	}
}

.input-media-text {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	color: $color-gray;
	&-white {
		font-weight: 700;
		color: $color-white;
		transition: color 0.2s ease-in-out;
	}
}

.active-drag {
	background-color: $color-black-additional;
}
</style>
