export default class ScrollKeeper {
	#prefix = 'scroll-'

	constructor() {
		if (ScrollKeeper._instance) {
			// eslint-disable-next-line no-constructor-return
			return ScrollKeeper._instance
		}
		ScrollKeeper._instance = this
	}

	saveScroll(name, position) {
		this[this.#prefix + name] = position
	}

	getSavedScroll(name) {
		return this[this.#prefix + name] || 0
	}
}
