export default class ModalState {
	constructor() {
		if (ModalState._instance) {
			// eslint-disable-next-line no-constructor-return
			return ModalState._instance
		}
		ModalState._instance = this
	}

	openModal(el, scrollY) {
		if (!this.el) {
			this.el = el
			document.body.style.overflow = 'hidden'
			document.documentElement.style.overflow = 'hidden'
			document.body.style.touchAction = 'none'
			document.body.style.position = 'fixed'
			document.body.style.top = `-${scrollY}px`
			document.body.style.left = '0'
			document.body.style.right = '0'
		}
	}

	closeModal(el) {
		if (el === this.el) {
			this.el = null
			const scrollY = Math.abs(document.body.style.top.replace(/px/gi, ''))
			document.body.style.overflow = ''
			document.documentElement.style.overflow = ''
			document.body.style.touchAction = 'auto'
			document.body.style.position = ''
			document.body.style.top = ''
			document.body.style.left = ''
			document.body.style.right = ''
			window.scrollTo({ top: scrollY })
		}
	}
}
