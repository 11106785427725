const allowedErrorsMessages = [
	/^Unsubscribed: unsubscribe normally$/gm,
	/^Unsubscribed: consumer has unsubscribed$/gm,
	/^Unsubscribed: consumer undefined$/gm,
	/^Unsubscribed: temp using service is over$/gm,
	/^Unsubscribed: subscription expired$/gm,
	/^Unsubscribed: subscription failed$/gm,
	/^Unsubscribed: insufficient funds$/gm,
	/^Unsubscribed: unsubscribed to only one girl$/gm,
	/^Subscription Failed: consumer could not subscribe to girl with temafon code: \d{3}$/gm,
	/^Subscription Waiting: consumer is waiting subscription to only one girl: (\w+)$/gm
]

const exceptionTransactions401 = ['subswait']

export { allowedErrorsMessages, exceptionTransactions401 }
