import { http } from '@/http/index'

async function getComments(consumerId) {
	return http.get(`/consumers/${consumerId}/comments`).then((response) => response.data)
}

async function getMoreComments(url) {
	return http.get(url).then((response) => response.data)
}

export { getComments, getMoreComments }
