import { http } from '@/http/index'

async function getLikedPosts(consumerId, sort_by = 'date') {
	const params = {
		sort_by
	}
	return http.get(`/consumers/${consumerId}/posts-which-liked`, { params }).then((response) => response.data)
}

async function getMoreLikedPosts(url, sort_by = 'date') {
	const params = {
		sort_by
	}
	return http.get(url, { params }).then((response) => response.data)
}

export { getLikedPosts, getMoreLikedPosts }
