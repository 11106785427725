<template>
	<!-- eslint-disable-next-line vue/no-unused-vars -->
	<validation-observer ref="form" tag="div" v-slot="{ handleSubmit }">
		<form @submit.prevent="onSubmit">
			<slot />
		</form>
	</validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
	name: 'FormComponent',
	components: { ValidationObserver },
	methods: {
		onSubmit() {
			this.$refs.form.validate().then((success) => {
				if (!success) {
					return
				}
				this.$emit('submit')
			})
		},
		// refactor
		// eslint-disable-next-line vue/no-unused-properties
		setErrors(errors) {
			this.$refs.form.setErrors(errors)
		}
	}
}
</script>
