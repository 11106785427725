<template>
	<div>
		<div class="content">
			<slot />
		</div>
		<nav-menu v-if="showMenu" />
	</div>
</template>

<script>
import NavMenu from '@/components/NavMenu.vue'

export default {
	name: 'DefaultLayout',
	components: { NavMenu },
	computed: {
		showMenu() {
			return this.$auth.check() || this.isGuest
		},
		isGuest() {
			return JSON.parse(this.$auth.remember())?.role === 'guest'
		}
	}
}
</script>
