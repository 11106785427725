import Vue from 'vue'
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm'
import router from '@/router/index'
import driverAuth from '@/config/driverAuth'

Vue.use(auth, {
	rememberkey: 'auth_remember',
	tokenDefaultKey: 'auth_token_default',
	tokenImpersonateKey: 'auth_token_impersonate',
	stores: ['storage', 'cookie'],
	plugins: {
		http: Vue.axios,
		router
	},
	drivers: {
		auth: driverAuth,
		http: driverHttpAxios,
		router: driverRouterVueRouter
	},
	options: {
		rolesKey: 'role',
		notFoundRedirect: { name: 'home' },
		loginData: { enabled: false },
		fetchData: { enabled: false },
		refreshData: { enabled: false },
		authRedirect: { name: 'login' },
		forbiddenRedirect: null
	}
})
