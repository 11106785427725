<template>
	<dialog-component class="sub-limit-dialog" @close="$emit('close')" :is-loading="isLoading">
		<template #header-title>Ограничение подписок</template>
		<div class="sub-limit">
			<div class="top-section">
				<div
					class="user-avatar"
					:style="{ 'background-image': `url('${profile?.avatar || subLimitModalGirl?.avatar}')` }"
				/>
				<div class="user-descr">
					Чтобы стать моим фанатом, тебе придется сначала отказаться от подписки на одну из этих моделей:
				</div>
			</div>
			<div class="sub-limit-list history flex row start">
				<div v-for="girl in followingGirls?.data" :key="`sl${girl.id}`" v-bind="girl">
					<div
						v-if="!girl.stopped_subscription_renewal"
						@click="onSelect(girl.id)"
						class="history-item"
						:class="{
							active: girl.id === pickGirlId
						}"
					>
						<div class="image-container relative">
							<div class="image placeholder-bg">
								<img v-if="girl.avatar" :src="girl.avatar" alt="Аватар" />
							</div>
						</div>
						<div class="history-item-name">{{ girl.nickname }}</div>
					</div>
				</div>
			</div>
		</div>

		<slot v-if="!isLoading" />
		<template v-if="!isLoading" #dialog-footer>
			<div class="confirmation-dialog-buttons">
				<ButtonComponent color="white" @click="$emit('decline')"> Нет, передумал </ButtonComponent>
				<ButtonComponent color="transparent" :disabled="isWaitingPickGirl" @click="onUnsubscribeSubLimit">
					Да, отписаться
				</ButtonComponent>
			</div>
		</template>

		<div v-else class="sub-limit-waiting">
			<div class="i-spinner rotating text-white" />
			<div>Нужно немножко подождать.</div>
		</div>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapActions, mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useProfileStore } from '@/stores/profile'
import { useModalStore } from '@/stores/modal'

export default {
	name: 'SubLimitDialog',
	components: { DialogComponent, ButtonComponent },
	props: {
		btnAcceptText: {
			type: String,
			default: 'Да'
		},
		btnDeclineText: {
			type: String,
			default: 'Нет'
		},
		disabled: {
			type: Boolean,
			default: () => false
		}
	},
	data() {
		return {
			pickGirlId: null,
			isWaitingPickGirl: true
		}
	},
	computed: {
		...mapState(useGirlStore, { profile: 'profile', isLoading: 'isLoading' }),
		...mapState(useProfileStore, {
			consumerId: 'id',
			followingGirls: 'followingGirls'
		}),
		...mapState(useModalStore, ['subLimitModalGirl']),
		subscribeLink() {
			const host = this.$config.apiUrl
			return `${host}consumers/${this.consumerId}/girls/${this.subLimitModalGirl?.id}/subscribe`
		}
	},
	methods: {
		...mapActions(useModalStore, {
			closeSubLimitModal: 'closeSubLimitModal'
		}),
		...mapActions(useGirlStore, {
			unsubscribe: 'unsubscribe'
		}),
		async onUnsubscribeSubLimit() {
			try {
				await this.unsubscribe(this.pickGirlId)
			} finally {
				this.closeSubLimitModal()
				window.location.href = this.subscribeLink
			}
		},
		onSelect(id) {
			this.pickGirlId = id
			this.isWaitingPickGirl = false
		}
	}
}
</script>

<style lang="scss">
.sub-limit-dialog {
	color: $color-gray;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	& .dialog {
		min-height: 478px;
	}
	&-buttons {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	& .sub-limit {
		& .top-section {
			display: flex;
			align-items: center;
			& .user-avatar {
				margin-right: 10px;
			}
			& .user-descr {
				color: $color-black;
				background-color: $color-gray-light-additional-third;
				padding: 4px 8px;
				border-radius: 3px 12px 12px 12px;
			}
		}
		&-list {
			margin-top: 20px;
			& img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 12px;
			}
			& .placeholder-bg {
				background-image: url('@/assets/images/model-avatar-default.png');
				background-size: 140%;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
		&-waiting {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.i-spinner {
				display: inline-flex;
				font-size: 80px;
				width: 80px;
				margin: 100px 0;
			}
		}
	}
}
</style>
