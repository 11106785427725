import { http } from '@/http/index'

async function getMessages({ threadId = null, nextPageRoute = null }, signal) {
	if (threadId) {
		return http.get(`/messenger/threads/${threadId}/messages`, { signal }).then((response) => response.data)
	}
	if (nextPageRoute) {
		return http.get(nextPageRoute, { signal }).then((response) => response.data)
	}
	return null
}

async function getMessage(threadId, messageId) {
	return http.get(`/messenger/threads/${threadId}/messages/${messageId}`).then((response) => response.data)
}

export { getMessages, getMessage }
