import { loadAvatar } from './loadAvatar'
import { loadCover } from './loadCover'
import { updateProfile } from './updateProfile'
import { getGirlProfile } from './girls'
import { getProfile } from './profile'

export default {
	loadAvatar,
	loadCover,
	updateProfile,
	getGirlProfile,
	getProfile
}
