import { http } from '@/http/index'

async function getReferralLink() {
	return http.get('/girl/referral/link').then((response) => response.data.data)
}

async function changeReferralGreeting(link, data) {
	return http.post(`girl/referral/${link}`, data).then((response) => response.data.data)
}

async function getReferrals(orderBy = '', desc = false) {
	const order = desc ? 'order_by_desc' : 'order_by'
	return http.get(`/girl/referral/referrals`, { params: { [order]: orderBy } }).then((response) => response.data)
}

async function getMoreReferrals(url) {
	return http.get(url).then((response) => response.data)
}

export { getReferralLink, changeReferralGreeting, getReferrals, getMoreReferrals }
