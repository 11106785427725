export default new IntersectionObserver(
	(entries, observer) => {
		entries.forEach(async (entry) => {
			if (entry.isIntersecting) {
				entry.target.loadImage()
				observer.unobserve(entry.target)
			}
		})
	},
	{ root: null, rootMargin: '100%' }
)
