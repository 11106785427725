<template>
	<collapsed-text
		class="text-with-hashtags-and-mentioned"
		:text="text"
		:preview-letters-count="previewLettersCount"
		:transform-text="formatPreviewText"
		:expand-text="expandText"
		@click.native.prevent="onClick($event)"
	/>
</template>

<script>
import CollapsedText from '@/components/CollapsedText.vue'

export default {
	name: 'TextWithHashtagsAndMentioned',
	components: { CollapsedText },
	props: {
		text: { type: String, default: '' },
		// hashtags: { type: Array, default: () => [] },
		mentioned: { type: Object, default: () => {} },
		previewLettersCount: { type: Number, default: 80 },
		expandText: { type: Boolean, default: true }
	},
	methods: {
		removeLastLetterByMask(str) {
			return str.replace(/(\s|$|,|\.)$/, '')
		},
		formatPreviewText(text) {
			if (text) return this.replaceMentioned(this.replaceHashtags(text))
			return null
		},
		replaceHashtags(rawDescriptionText) {
			const regex = /#[a-zA-ZА-я\d]+(\s|$|,|\.)/g
			return rawDescriptionText.replace(regex, (match) => {
				const formattedTag = this.removeLastLetterByMask(match)
				return `<a href="/search/${formattedTag}" data-hashtag="${formattedTag}">${match}</a>`
			})
		},
		replaceMentioned(rawDescriptionText) {
			const regex = /@[a-zA-ZА-я\d]+(\s|$|,|\.)/g
			if (this.mentioned?.consumers?.length || this.mentioned?.girls?.length) {
				return rawDescriptionText.replace(regex, (match) => {
					const consumerMention = this.mentioned.consumers?.find(
						(el) => el.nickname === this.removeLastLetterByMask(match).substring(1)
					)
					const girlMention = this.mentioned.girls?.find(
						(el) => el.nickname === this.removeLastLetterByMask(match).substring(1)
					)
					if (consumerMention) {
						return `<a href='/f/${consumerMention.nickname}' data-consumer-id='${consumerMention.user_id}' data-consumer-nickname='${consumerMention.nickname}'>${match}</a>`
					}
					if (girlMention) {
						return `<a href='/${girlMention.nickname}' data-girl-id='${girlMention.user_id}' data-girl-nickname='${girlMention.nickname}'>${match}</a>`
					}
					return match
				})
			}
			return rawDescriptionText
		},
		hashtagRedirect(hashtag) {
			this.$router.push({
				name: 'search',
				query: {
					searchValue: hashtag
				}
			})
		},
		consumerMentionRedirect(consumerId, consumerNickname) {
			let consumerNicknameParam
			let routeName
			if (this.$route.name !== 'consumer-reactions-id') {
				consumerNicknameParam = consumerNickname
				routeName = 'consumer-profile'
			} else {
				routeName = 'consumer-profile-id'
			}
			this.$router.push({
				name: routeName,
				params: {
					id: consumerId,
					nickname: consumerNicknameParam
				}
			})
		},
		girlMentionRedirect(girlId, girlNickname) {
			this.$router.push({
				name: 'author-profile',
				params: {
					id: girlId,
					nickname: girlNickname
				}
			})
		},
		onClick(e) {
			if (e.target.tagName === 'A') {
				const { hashtag, consumerId, girlId, consumerNickname, girlNickname } = e.target.dataset
				if (hashtag) {
					this.hashtagRedirect(hashtag)
					return
				}
				if (consumerId) {
					this.consumerMentionRedirect(consumerId, consumerNickname)
					return
				}
				if (girlId) {
					this.girlMentionRedirect(girlId, girlNickname)
					return
				}
				if (!hashtag && !consumerId && !girlId) {
					window.open(e.target.href, '_blank')
				}
			}
		}
	}
}
</script>
