<template>
	<div :class="isDesktop ? 'desktop-wrap' : 'mobile-wrap'">
		<cropper
			ref="cropper"
			class="cropper"
			:src="previewCropper"
			:min-width="minStencilWidth"
			:resize-image="false"
			:init-stretcher="initStretcher"
			:stencil-props="{
				minAspectRatio: 0.75,
				maxAspectRatio: 2.3
			}"
			:default-size="defaultSize"
			:default-position="defaultPosition"
		/>
		<div class="edit-post-btngroup">
			<ButtonComponent color="transparent" :loading="isLoadingImage" @click="changePhoto">
				Изменить фото
			</ButtonComponent>
			<ButtonComponent color="primary" :loading="isLoadingImage" @click="getCroppedImage"> Сохранить </ButtonComponent>
		</div>
	</div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapWritableState } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'

export default {
	name: 'CropperWrapper',
	components: {
		ButtonComponent,
		Cropper
	},
	props: {
		previewCropper: {
			type: String,
			default: ''
		},
		isDesktop: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			minStencilWidth: 1000,
			isLoadingImage: false,
			coordinates: null
		}
	},
	computed: {
		...mapWritableState(useCreatePostStore, ['croppedBase64']),
		defaultSize() {
			if (this.coordinates) {
				return {
					width: this.coordinates.width,
					height: this.coordinates.height
				}
			}
			return undefined
		},
		defaultPosition() {
			if (this.coordinates) {
				return {
					top: this.coordinates.top,
					left: this.coordinates.left
				}
			}
			return undefined
		}
	},
	methods: {
		initStretcher({ stretcher, imageSize }) {
			const imageHeight = stretcher.clientWidth * (imageSize.height / imageSize.width)
			stretcher.style.height = `${Math.min(imageHeight, 513, window.innerHeight * 0.45)}px`
		},
		getCroppedImage() {
			this.isLoadingImage = true
			const { canvas } = this.$refs.cropper.getResult()
			const { width, height, top, left } = this.$refs.cropper.coordinates
			this.coordinates = this.$refs.cropper.coordinates
			this.croppedBase64 = canvas.toDataURL()
			this.sendCropEvent(width, height, left, top)
			this.isLoadingImage = false
		},
		sendCropEvent(width, height, x, y) {
			this.$emit('crop', {
				width: Math.round(width),
				height: Math.round(height),
				x: Math.round(x) || 1,
				y: Math.round(y) || 1
			})
		},
		changePhoto() {
			this.$emit('changePhoto')
		}
	}
}
</script>

<style lang="scss" scoped>
.cropper-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.cropper-wrapper-desktop {
	height: 100%;
	background-color: $color-black-additional-secondary;
	padding: 20px 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.edit-post-btngroup {
	margin-top: 43px;
	margin-top: auto;
	padding-top: 20px;
	@media (min-width: $screen-desktop) {
		margin-top: 22px;
		display: flex;
		justify-content: center;
	}
	& .btn:first-child {
		margin-right: 13px;
	}
}

.edit-post-btngroup *:first-child {
	margin-bottom: 15px;
}
</style>
