<template>
	<div class="content p-0">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'EmptyLayout'
}
</script>
