import { http } from '@/http/index'

async function enableSmsNotification() {
	return http.put('/consumer/sms-notifications-social', {
		sms_notifications_social_on: true
	})
}

async function disableSmsNotification() {
	return http.put('/consumer/sms-notifications-social', {
		sms_notifications_social_on: false
	})
}

export { enableSmsNotification, disableSmsNotification }
