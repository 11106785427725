import { http } from '@/http/index'

export const getReactions = async (threadId, messageId) =>
	http.get(`/messenger/threads/${threadId}/messages/${messageId}/reactions`).then((response) => response.data)
export const setReaction = async (threadId, messageId, data) =>
	http.post(`/messenger/threads/${threadId}/messages/${messageId}/reactions`, data).then((response) => response.data)

export const removeReaction = async (threadId, messageId, reaction) =>
	http
		.delete(`/messenger/threads/${threadId}/messages/${messageId}/reactions/${reaction}`)
		.then((response) => response.data)
