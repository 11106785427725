<template functional>
	<div class="history-item opacity" v-bind="data.attrs" v-on="listeners">
		<div class="history-item-btn flex center">
			<div class="history-item-add">+</div>
		</div>
		<div class="history-item-name">Увидеть всех</div>
	</div>
</template>

<script>
export default {
	name: 'FilterCardButton'
}
</script>
