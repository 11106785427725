import { defineStore } from 'pinia'
import {
	getConsumersLists,
	createConsumersList,
	updateConsumersList,
	removeConsumersList,
	getConsumers,
	addConsumerToList
} from '@/http/girl'

export const useConsumersListsApiStore = defineStore('consumersListsApiStore', {
	actions: {
		fetchAll(params) {
			return getConsumersLists(params)
		},
		createOne(data) {
			return createConsumersList(data)
		},
		updateOne(id, data) {
			return updateConsumersList(id, data)
		},
		deleteOne(id) {
			return removeConsumersList(id)
		},
		getConsumers(params) {
			return getConsumers(params)
		},
		addConsumerToList(listId, consumerId) {
			return addConsumerToList(listId, consumerId)
		}
	}
})
