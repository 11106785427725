import { defineStore } from 'pinia'
// import { useProfileStore } from '@/stores/profile'
import posts, { getMoreData } from '@/stores/helper'
import consumerApi from '@/http/consumer'

export const useConsumerStore = defineStore('consumer', {
	state: () => ({
		profile: null,
		awards: null,
		comments: null,
		likedPosts: null,
		girlsWithTotalLikesByPosts: null,
		postsWithTotalConsumerLikes: null,
		isLoading: false
	}),
	getters: {
		awardsHasLoaded: (state) => state.awards !== null,
		likedPostsHasLoaded: (state) => state.likedPosts !== null,
		commentsHasLoaded: (state) => state.comments !== null
	},
	actions: {
		updateLikes(post) {
			posts.findPostAndUpdateLikesValue(this.awards, post)
			posts.findPostAndUpdateLikesValue(this.likedPosts, post)
		},
		applyBlurToPostsOnlyForFans(girlId) {
			if (this.awards) {
				posts.mediaContentVisibleOnlyForFans(this.awards.data, girlId)
			}
			if (this.likedPosts) {
				posts.mediaContentVisibleOnlyForFans(this.likedPosts.data, girlId)
			}
		},
		removeBlurFromPosts(girlId) {
			if (this.awards) {
				posts.medialContentVisibleForAllConsumers(this.awards.data, girlId)
			}
			if (this.likedPosts) {
				posts.medialContentVisibleForAllConsumers(this.likedPosts.data, girlId)
			}
		},
		turnOnWaitingSubscription(girlId) {
			if (this.awards) {
				posts.turnOnWaitingSubscription(this.awards.data, girlId)
			}
			if (this.likedPosts) {
				posts.turnOnWaitingSubscription(this.likedPosts.data, girlId)
			}
		},
		async getPostsWithTotalConsumerLikes(consumerId) {
			try {
				this.postsWithTotalConsumerLikes = null
				this.isLoading = true
				const result = await consumerApi.getPostsWithTotalConsumerLikes(consumerId)
				if (result) {
					this.postsWithTotalConsumerLikes = result
				}
			} finally {
				this.isLoading = false
			}
		},
		async getMorePostsWithTotalConsumerLikes() {
			await getMoreData(this.postsWithTotalConsumerLikes, consumerApi.getMorePostsWithTotalConsumerLikes)
		},
		async getLikedPosts(consumerId) {
			if (this.likedPosts === null) {
				try {
					this.likedPosts = null
					this.isLoading = true
					const result = await consumerApi.getLikedPosts(consumerId)
					if (result) {
						this.likedPosts = result
					}
				} finally {
					this.isLoading = false
				}
			}
		},
		async getMoreLikedPosts() {
			await getMoreData(this.likedPosts, consumerApi.getMoreLikedPosts)
		},
		async getGirlsWithTotalLikesByPosts(consumerId) {
			this.girlsWithTotalLikesByPosts = null
			this.isLoading = true
			const result = await consumerApi.getGirlsWithTotalLikesByPosts(consumerId)
			if (result) {
				this.girlsWithTotalLikesByPosts = result
			}
			this.isLoading = false
		},
		async getMoreGirlsWithTotalLikesByPosts() {
			await getMoreData(this.girlsWithTotalLikesByPosts, consumerApi.getMoreGirlsWithTotalLikesByPosts)
		},
		async getConsumer(consumerId) {
			this.isLoading = true
			try {
				const result = await consumerApi.getConsumer(consumerId)
				if (result) {
					this.profile = result
				}
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async getConsumerByNickname(nickname) {
			this.isLoading = true
			try {
				const result = await consumerApi.getConsumerByNickname(nickname)
				if (result) {
					this.profile = result
				}
			} catch (error) {
				// eslint-disable-next-line
				console.trace(error)
				throw error
			}
			this.isLoading = false
		},
		async getAwards(consumerId) {
			if (this.awards === null) {
				try {
					this.awards = null
					this.isLoading = true
					const result = await consumerApi.getAwards(consumerId)
					if (result) {
						this.awards = result
					}
				} finally {
					this.isLoading = false
				}
			}
		},
		async getMoreAwards() {
			await getMoreData(this.awards, consumerApi.getMoreAwards)
		},
		async getComments(consumerId) {
			if (this.comments === null) {
				try {
					this.comments = null
					this.isLoading = true
					const result = await consumerApi.getComments(consumerId)
					if (result) {
						this.comments = result
					}
				} finally {
					this.isLoading = false
				}
			}
		},
		async getMoreComments() {
			await getMoreData(this.comments, consumerApi.getMoreComments)
		},
		async sendComplaint(consumerId) {
			await consumerApi.sendComplaint(consumerId)
		},
		removePostFromList(postId) {
			posts.removePostFromList(this.awards, postId)
			posts.removePostFromList(this.likedPosts, postId)
		},
		removeTabsInfo() {
			this.likedPosts = null
			this.awards = null
			this.comments = null
			this.profile = null
			this.girlsWithTotalLikesByPosts = null
			this.postsWithTotalConsumerLikes = null
		}
	}
})
