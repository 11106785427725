<template>
	<div class="schedule-item">
		<div class="schedule-item-time placeholder-bg" />
		<div class="schedule-body placeholder-bg">
			<div class="schedule-img-wrap placeholder-bg-in" />
			<div class="schedule-body-descr">
				<div class="schedule-body-top">
					<div class="schedule-body-status placeholder-bg-in" />
				</div>
				<div class="schedule-body-text placeholder-bg-in" />
				<div class="schedule-body-text second placeholder-bg-in" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PlaceholderScheduleEvents'
}
</script>
