<template>
	<div class="menu flex row space">
		<div class="menu-item" style="order: 2" v-if="$auth.check('girl')">
			<input-media ref="mediaInput" :rules="{ minDimensions: [1000, 217] }" />
			<create-button :type="createButtonState" :percentage="progress" @openCreatePostDialog="openMedia" />
		</div>
		<div
			v-for="(link, i) in isConsumer ? consumerLinks : isGirl ? girlLinks : guestLinks"
			:key="link.icon"
			:style="{ order: i }"
			class="menu-item-wrap"
			@click="resetFilterAndScroll(link)"
		>
			<router-link
				:to="link.to"
				class="menu-item"
				:data-navigation-link="link.icon"
				:class="{ active: setActiveTab(link.to.name) }"
			>
				<div class="icon" :class="[[link.icon], { indicator: link.icon === 'notifications' && hasNotifications }]">
					<div class="nav-counter" v-if="isPrivatesEnabled && unreadThreadsCount && link.icon === 'message'">
						{{ unreadThreadsCount }}
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useHomeStore } from '@/stores/home'
import { useCreatePostStore } from '@/stores/createPost'
import { createButtonTypes } from '@/constants'
import InputMedia from '@/components/create-post/InputMedia.vue'
import { useMessengerStore } from '@/stores/messenger'
import CreateButton from './CreateButton.vue'

const commonRoutes = [
	{
		to: { name: 'home' },
		icon: 'home',
		exactActiveClass: 'active'
	},
	{
		to: { name: 'search' },
		icon: 'search',
		activeClass: 'active'
	}
]

export default {
	name: 'NavMenu',
	components: { CreateButton, InputMedia },
	computed: {
		isConsumer() {
			return this.$auth.check('consumer')
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		...mapState(useVideoUploadStore, ['progress']),
		...mapState(useGirlProfileStore, { girlNotifications: 'notifications' }),
		...mapState(useProfileStore, {
			consumerId: 'id',
			consumerNotifications: 'notifications',
			consumerNickname: 'nickname',
			consumerPrivates: 'show_privates'
		}),
		...mapState(useGirlProfileStore, {
			girlId: 'id',
			girlNickname: 'nickname',
			girlNotifications: 'notifications',
			verification_status: 'verification_status',
			nickname_approved: 'nickname_approved',
			girlPrivates: 'show_privates'
		}),
		...mapState(useMessengerStore, {
			unreadThreadsCount: 'unreadThreadsCount'
		}),
		...mapState(useCreatePostStore, ['post']),
		hasNotifications() {
			return this.girlNotifications > 0 || this.consumerNotifications > 0
		},
		isPrivatesEnabled() {
			return this.girlPrivates || this.consumerPrivates
		},
		consumerLinks() {
			return this.consumerId
				? [
						...commonRoutes,
						{
							to: { name: this.isPrivatesEnabled ? 'chat' : 'consumer-notifications' },
							icon: this.isPrivatesEnabled ? 'message' : 'notifications',
							activeClass: 'active'
						},
						{
							to: {
								name: 'consumer-profile',
								params: { id: this.consumerId, nickname: this.consumerNickname }
							},
							icon: 'profile',
							activeClass: 'active'
						}
					]
				: false
		},
		girlLinks() {
			return this.girlId
				? [
						...commonRoutes,
						{
							to: { name: this.isPrivatesEnabled ? 'chat' : 'author-notifications' },
							icon: this.isPrivatesEnabled ? 'message' : 'notifications',
							activeClass: 'active'
						},
						{
							to: this.getAuthorProfile(),
							icon: 'profile',
							activeClass: 'active'
						}
					]
				: false
		},
		guestLinks() {
			return [
				...commonRoutes,
				{
					to: { name: 'login' },
					icon: this.isPrivatesEnabled ? 'message' : 'notifications',
					activeClass: 'active'
				},
				{
					to: { name: 'login' },
					icon: 'profile',
					activeClass: 'active'
				}
			]
		},
		createButtonState() {
			if (this.progress === 0) {
				return createButtonTypes.default
			}
			if (this.progress === 100) {
				return createButtonTypes.ready
			}
			return createButtonTypes.loading
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['openCreatePostDialog', 'hideValidationDialog']),
		...mapActions(useHomeStore, ['resetFilter']),
		...mapActions(useVideoUploadStore, ['initUploader', 'addFile']),
		openMedia() {
			this.$refs.mediaInput.openMedia()
		},
		onLoadEnd(fileInfo) {
			if (fileInfo && fileInfo.type === 'video') {
				this.initUploader(this.$refs.browse)
				this.addFile(fileInfo.file)
			}
			if (fileInfo && fileInfo.type === 'image') {
				this.post.photo = fileInfo.file
				this.openCreatePostDialog()
			}
		},
		getAuthorProfile() {
			if (!this.nickname_approved || this.verification_status === 'needs') {
				return { name: 'author-profile-id', params: { id: this.girlId } }
			}
			return { name: 'author-profile', params: { nickname: this.girlNickname, id: this.girlId } }
		},
		resetFilterAndScroll(link) {
			if (link.to.name === 'home') {
				this.resetFilter()
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})
			}
		},
		setActiveTab(tabName) {
			return this.$route.name === tabName
		}
	}
}
</script>

<style style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.menu {
	display: none;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	background: $color-black-secondary;
	border-radius: 16px 16px 0 0;
	height: 78px;
	gap: 0;
	position: fixed;
	bottom: -1px;
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	left: 50%;
	z-index: 888;
	transform: translate(-50%);
	outline: 1px solid $color-black-secondary;
	@media (max-width: $screen-desktop) {
		display: flex;
	}
}

.menu-item-wrap {
	width: 25%;
	height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.menu-item {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.menu-item .icon {
	width: 28px;
	height: 28px;
	min-height: 28px;
	min-width: 28px;
	display: flex;
	flex-direction: row;
	justify-items: center;
	align-items: center;
	background-size: contain !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
}

.menu-item .icon.home {
	background-image: url('@/assets/images/icons/home-disable.png');
}

.menu-item.active .icon.home {
	background-image: url('@/assets/images/icons/home-active.png');
}

.menu-item .icon.notifications {
	background-image: url('@/assets/images/icons/notification-disable.png');
}

.menu-item .icon.message {
	background-image: url('@/assets/images/icons/message-disable.png');
}
.menu-item.active .icon.message {
	background-image: url('@/assets/images/icons/message-active.png');
}

.menu-item .icon.notifications.indicator {
	position: relative;
}

.menu-item .icon.notifications.indicator::after {
	content: '';
	width: 6px;
	height: 6px;
	background: $color-blue-dark;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: 0;
}

.menu-item.active .icon.notifications {
	background-image: url('@/assets/images/icons/notification-active.png');
}

.menu-item .icon.search {
	background-image: url('@/assets/images/icons/search-disable.png');
}

.menu-item.active .icon.search {
	background-image: url('@/assets/images/icons/search-active.png');
}

.menu-item .icon.profile {
	background-image: url('@/assets/images/icons/profile-disable.png');
}

.menu-item.active .icon.profile {
	background-image: url('@/assets/images/icons/profile-active.png');
}

.icon.message.counter {
	position: absolute;
}
.nav-counter {
	font-size: 12px;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	color: $color-white;
	background: $color-blue-dark;
	height: 20px;
	padding: 0 7px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -6px;
	left: 16px;
	position: absolute;
}
</style>
