import { getRecommendedPost, getMoreRecommendedPost } from './recommended'
import { likeComment } from './likeComment'
import { deletePost } from './deletePost'
import { consumersWithLikes, getMoreConsumersWithLikes } from './consumersWithLikes'
import { like } from './like'
import { searchPostsByDescription } from './searchPostsByDescription'
import {
	getPost,
	getPosts,
	getMorePosts,
	getPostComments,
	sendComment,
	getMorePostComments,
	getPostsByGirlId
} from './posts'

export default {
	like,
	getPost,
	getPosts,
	getPostsByGirlId,
	deletePost,
	likeComment,
	getMorePosts,
	getPostComments,
	sendComment,
	getMorePostComments,
	searchPostsByDescription,
	getRecommendedPost,
	getMoreRecommendedPost,
	consumersWithLikes,
	getMoreConsumersWithLikes
}
