import { http } from '@/http/index'

async function getGirlsWithTotalLikesByPosts(consumerId) {
	return http.get(`/consumers/${consumerId}/girls-with-likes`).then((response) => response.data)
}

async function getMoreGirlsWithTotalLikesByPosts(url) {
	return http.get(url).then((response) => response.data)
}

export { getGirlsWithTotalLikesByPosts, getMoreGirlsWithTotalLikesByPosts }
