const locale = 'ru-RU'

export const toDayAndMonth = (date) =>
	date ? `${`0${new Date(date).getDate()}`.slice(-2)}.${`0${new Date(date).getMonth() + 1}`.slice(-2)}` : ''

export const hoursMinutes = (date) =>
	new Intl.DateTimeFormat(locale, {
		hour: 'numeric',
		minute: 'numeric'
	}).format(new Date(date))

export const dayMonthYear = (date) =>
	new Intl.DateTimeFormat(locale, {
		day: 'numeric',
		month: 'long',
		year: 'numeric'
	}).format(new Date(date))

export const dayMonthYearNumeric = (date) =>
	new Intl.DateTimeFormat(locale, {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric'
	}).format(new Date(date))

export const dayMonth = (date) =>
	new Intl.DateTimeFormat(locale, {
		day: 'numeric',
		month: 'long'
	}).format(new Date(date))
