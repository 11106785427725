export const detectIOS = () => {
	if (typeof window === `undefined` || typeof navigator === `undefined`) return false
	const ua = window.navigator.userAgent
	return ua.match(/iPad/i) || ua.match(/iPhone/i)
}

export const detectSafari = () =>
	navigator.vendor &&
	navigator.vendor.indexOf('Apple') > -1 &&
	navigator.userAgent &&
	navigator.userAgent.indexOf('CriOS') === -1 &&
	navigator.userAgent.indexOf('FxiOS') === -1
