import { http } from '../index'

async function setThreadUserNotification(userType, threadId, state) {
	return http
		.post(`/${userType}/messenger/${threadId}/chat-push-notifications`, { enabled: state })
		.then((response) => response.data)
}

async function setChatUserNotification(userType, state) {
	return http.patch(`/${userType}/chat-push-notifications`, { enabled: state }).then((response) => response.data)
}

async function getChatPushState() {
	return http.get('/push-settings').then((response) => response.data)
}

async function setPushSubscription(data) {
	return http.post('/push-subscribe', data).then((response) => response.data)
}

async function sendPushStatistics(data) {
	return http.post('/log-web-push', data).then((response) => response.data)
}

export { setChatUserNotification, setThreadUserNotification, getChatPushState, setPushSubscription, sendPushStatistics }
