<template>
	<div>
		<dialog-component v-if="verificationStatus === 'needs'" @close="closeDialog">
			<template #header-title>Требуется верификация.</template>
			<p class="status-dialog-text">
				Мы должны убедиться, что ты — настоящая. Приготовься сделать селфи с паспортом. После проверки тебе станет
				доступен просмотр чужих постов, а твои собственные посты опубликуются автоматически.
			</p>
			<ButtonComponent @click="startVerification"> Начать верификацию </ButtonComponent>
		</dialog-component>
		<dialog-component v-if="verificationStatus === 'in_process'" @close="closeDialog">
			<template #header-title>Верификация. Почти готово.</template>
			<p class="status-dialog-text">
				Нужно немного подождать, пока мы проверим данные. Обычно процесс занимает не более 8 часов. После этого тебе
				станет доступен просмотр чужих постов, а твои собственные посты опубликуются автоматически.
			</p>
			<ButtonComponent :loading="isSending" @click="closeDialog"> Отлично </ButtonComponent>
		</dialog-component>
		<dialog-component v-if="verificationStatus === 'rejected'" @close="closeDialog">
			<template #header-title>Требуется верификация.</template>
			<p class="status-dialog-text">Мы не смогли убедиться, что ты — настоящая. Попробуй повторить шаги верификации.</p>
			<ButtonComponent :loading="isSending" @click="startVerification"> Повторить верификацию </ButtonComponent>
		</dialog-component>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useGirlProfileStore } from '@/stores/girlProfile'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'GirlVerificationStatusDialog',
	components: { ButtonComponent, DialogComponent },
	data() {
		return {
			isSending: false
		}
	},
	computed: {
		...mapState(useGirlProfileStore, { verificationStatus: 'verification_status' })
	},
	methods: {
		...mapActions(useModalStore, { openVerificationDialog: 'openVerificationDialog' }),
		startVerification() {
			this.$emit('close')
			this.openVerificationDialog()
		},
		closeDialog() {
			this.$emit('close')
		}
	}
}
</script>
