import { http } from '@/http'

async function getConsumer(consumerId) {
	return http.get(`/consumers/${consumerId}`).then((response) => response.data.data)
}

async function getConsumerByNickname(nickname) {
	return http.get(`/consumers?nickname=${nickname}`).then((response) => response.data.data)
}

export { getConsumer, getConsumerByNickname }
