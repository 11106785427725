import { http } from '@/http/index'

async function getPosts(girlId) {
	return http.get(`/girls/${girlId}/posts/audience/total`).then((response) => response.data)
}

async function getMorePosts(url) {
	return http.get(url).then((response) => response.data)
}

export { getPosts, getMorePosts }
