import { http } from '@/http/index'

async function editPostWithPhoto(postId, data) {
	return http.post(`/girls/posts/photo/${postId}`, data)
}

async function editPostWithVideo(postId, data) {
	return http.post(`girls/posts/video/${postId}`, data)
}

async function getVideoPreview(postId) {
	return http.get(`girls/posts/video/${postId}/previews`).then((response) => response.data.data)
}

export { editPostWithPhoto, editPostWithVideo, getVideoPreview }
