import { http } from '@/http/index'

async function getPost(id) {
	return http.get(`/posts/${id}`).then((response) => response.data.data)
}

async function sendComment(id, comment) {
	return http
		.post(`posts/${id}/comments`, {
			comment
		})
		.then((response) => response.data.data)
}

async function getPostComments(id) {
	return http.get(`/posts/${id}/comments`).then((response) => response.data)
}

async function getMorePostComments(url) {
	return http.get(url).then((response) => response.data)
}

async function getPosts(controller) {
	const params = {}
	if (controller instanceof AbortController) {
		params.signal = controller.signal
	}
	return http
		.get(`/posts`, params)
		.then((response) => response.data)
		.catch((e) => {
			throw new Error(e)
		})
}

async function getPostsByGirlId(girlId) {
	return http.get(`/girls/${girlId}/posts`).then((response) => response.data)
}

async function getMorePosts(url) {
	return http.get(url).then((response) => response.data)
}

async function viewPost(posts) {
	return http.post('/post/view', { posts })
}

export {
	getPost,
	getPostComments,
	getPosts,
	getMorePosts,
	sendComment,
	getMorePostComments,
	viewPost,
	getPostsByGirlId
}
