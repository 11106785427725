import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import { useProfileStore } from '@/stores/profile'
import { useGirlProfileStore } from '@/stores/girlProfile'
import { useModalStore } from '@/stores/modal'
import { useGirlStore } from '@/stores/girl'
import commonRoutes from '@/router/commonRoutes'
import girlAuthRoutes from '@/router/girlAuthRoutes'
import girlRoutes from '@/router/girlRoutes'
import consumerRoutes from '@/router/consumerRoutes'
import ScrollKeeper from '@/helpers/scrollKeeper'
import { RefreshProfileClass } from '@/helpers/RefreshProfile.class'
import { useCurrentUserStore } from '@/stores/user'
import AppConfig from '@/config.class'

const scrollKeeper = new ScrollKeeper()
Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	routes: [...commonRoutes, ...girlAuthRoutes, ...girlRoutes, ...consumerRoutes],
	scrollBehavior(to) {
		let routeName = to.name
		const userId = to.params.id
		const nickName = to.params.nickname
		if (nickName) {
			routeName = `${routeName}/${nickName}`
		} else if (userId) {
			routeName = `${routeName}/${userId}`
		}
		const a = scrollKeeper.getSavedScroll(routeName)
		const scrollPosition = { x: 0, y: a }
		if (to.hash) {
			return { selector: to.hash }
		}
		return scrollPosition
	}
})

const exceptionRoutes = [
	'eula',
	'subswait',
	'privacy-policy',
	'author-start-waiting-subscription',
	'author-mk-start-waiting-subscription',
	'login-if-has-any-subscription-to-girl',
	'error-subscription-failed-only-one-girl',
	'error-subscription-waiting-only-one-girl'
]

const profileCheckRegEx = /^\/(a|author)\/([\wА-Яа-я]+)/

const intervalExceptionRoutes = ['consumer-edit', 'author-profile-edit']

router.beforeEach(async (to, from, next) => {
	// ToDo доделать currentUser логику, проставить meta.auth во всех children
	const currentUserStore = useCurrentUserStore()
	// if (!to.meta.auth) {
	// 	next()
	// 	return
	// }
	if (exceptionRoutes.includes(to.name)) {
		next()
		return
	}
	if (currentUserStore.isAuthorised === false) {
		if (Vue.auth.token() && Vue.auth.remember()) {
			const { role } = JSON.parse(Vue.auth.remember())
			currentUserStore.setIsAuthorised(true)
			currentUserStore.setUserRole(role)
			await currentUserStore.fetchCurrentUser()
		}
	}

	const isSameId = Number(to.params.id) === Number(from.params.id)
	const isSameNickname = to.params.nickname === from.params.nickname
	const isSameProfile = isSameId || isSameNickname
	const fromIsProfile = profileCheckRegEx.test(from.path)
	const toIsProfile = profileCheckRegEx.test(decodeURIComponent(to.path))
	const toIsOwnProfile = fromIsProfile && toIsProfile && !isSameProfile

	if ((!toIsProfile && !(to.name === 'post')) || toIsOwnProfile) {
		useGirlStore().resetStore()
	}

	useModalStore().showAppLoader()
	if (Vue.auth.token() && Vue.auth.remember()) {
		const metrika = window[`yaCounter${Vue.prototype.$config.yaMetrikaId}`]
		const isFeatureEnabled = Vue.prototype.$config.metrikaSdpUserIdFeature
		if (!!isFeatureEnabled && !!metrika && currentUserStore.isAuthorised) {
			const params = {
				role: currentUserStore.userRole,
				UserID: currentUserStore.currentUser.sdp_user_id
			}

			metrika.userParams(params)
		}
		const userData = JSON.parse(Vue.auth.remember())
		Vue.auth.user(userData)
		if (Vue.auth.check('consumer')) {
			const consumerProfileStore = useProfileStore()
			const profileInterval = new RefreshProfileClass(consumerProfileStore, 10000)
			const redirectCookie = Cookies.get('redirect_to')
			if (redirectCookie) {
				Cookies.remove('redirect_to')
				next(redirectCookie)
			}
			if (!consumerProfileStore.isProfileLoaded) {
				await consumerProfileStore.getProfile()
				profileInterval.refreshProfile(consumerProfileStore)
			}
			if (!profileInterval.getInterval()) {
				profileInterval.startInterval()
			}
			if (intervalExceptionRoutes.includes(to.name)) {
				profileInterval.stopInterval()
			}
		}
		if (Vue.auth.check('girl')) {
			const girlProfileStore = useGirlProfileStore()
			const profileInterval = new RefreshProfileClass(girlProfileStore, 10000)
			if (!girlProfileStore.isProfileLoaded) {
				await girlProfileStore.getProfile()
				profileInterval.refreshProfile(girlProfileStore)
			}
			if (!profileInterval.getInterval()) {
				profileInterval.startInterval()
			}
			if (intervalExceptionRoutes.includes(to.name)) {
				profileInterval.stopInterval()
			}
			if (!girlProfileStore.nickname) {
				if (to.name === 'author-profile-edit') {
					next()
					return
				}
				next({ name: 'author-profile-edit' })
				return
			}
		}
	} else {
		currentUserStore.setIsAuthorised(false)
		if (AppConfig.guestAccess) {
			Vue.auth.user({ role: 'guest' })
			Vue.auth.remember(JSON.stringify(Vue.auth.user()))
		}
	}
	next()
})

router.afterEach(() => {
	useModalStore().hideAppLoader()
})

export default router
