import { defineStore } from 'pinia'
import {
	getChatPushState,
	setChatUserNotification,
	setThreadUserNotification,
	setPushSubscription,
	sendPushStatistics
} from '@/http/common'

export const useNotificationsStore = defineStore('notifications', {
	state: () => ({
		allThreadsPushState: false,
		threadPushStateMap: new Map(),
		pushSubscriptionState: false
	}),
	getters: {
		getThreadPushStatus(state) {
			return (threadId) => state.threadPushStateMap.get(threadId)
		}
	},
	actions: {
		setAllThreadsPushState(state) {
			this.allThreadsPushState = state
		},
		setThreadPushState(threadId, state) {
			this.threadPushStateMap.set(threadId, state)
		},
		setPushSubscriptionState(state) {
			this.pushSubscriptionState = state
		},
		mapThreadPushState(threadList) {
			if (Array.isArray(threadList)) {
				threadList.forEach((e) => {
					this.setThreadPushState(e.thread_id, e.enabled)
				})
			}
		},
		async fetchChatPushState() {
			const { data: state } = await getChatPushState()
			this.setAllThreadsPushState(state.all_threads_webpush_on)
			this.mapThreadPushState(state.threads_settings)
		},
		async pushSubscribe(data) {
			try {
				await setPushSubscription(data)
			} catch (e) {
				throw new Error(e.toString())
			}
		},
		async changeChatPushState(userType, state) {
			try {
				const { chat_webpush_on: pushState } = await setChatUserNotification(userType, state)
				this.setAllThreadsPushState(pushState)
			} catch (e) {
				throw new Error(e.toString())
			}
		},
		async changeThreadPushState(userType, threadId, state) {
			try {
				const { enabled: threadState, thread_id } = await setThreadUserNotification(userType, threadId, state)
				this.setThreadPushState(thread_id, threadState)
			} catch (e) {
				throw new Error(e.toString())
			}
		},
		async postPushStatistics(data) {
			try {
				await sendPushStatistics(data)
			} catch (e) {
				throw new Error(e.toString())
			}
		}
	}
})
