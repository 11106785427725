import { defineStore } from 'pinia'
import { viewPost } from '@/http/posts/posts'

export const usePostsStore = defineStore('posts', {
	state: () => ({
		viewedPost: []
	}),
	actions: {
		sendViewedPost() {
			if (this.viewedPost.length > 0) {
				viewPost(this.viewedPost)
				this.clearViewedPost()
			}
		},
		clearViewedPost() {
			this.viewedPost = []
		},
		postViewed(id) {
			this.addViewedPost(id)
			if (this.viewedPost.length >= this.$config.numberPostsViewed)
				try {
					this.sendViewedPost()
				} catch (e) {
					throw new Error(e)
				}
		},
		addViewedPost(id) {
			this.viewedPost.push({ id, viewed_at: Math.floor(Date.now() / 1000) })
		}
	}
})
