<template functional>
	<div class="post flex column start" v-bind="data.attrs">
		<div class="post-header flex row space container">
			<div class="post-author-info w-full">
				<div class="avatar-circle placeholder" />
				<div class="post-data flex column center w-full">
					<div class="post-name w-full">
						<div class="placeholder w-75" />
					</div>
					<div class="post-release w-full">
						<div class="placeholder w-50" />
					</div>
				</div>
			</div>
			<div class="post-subscribe w-50">
				<div class="placeholder w-full" />
			</div>
		</div>
		<div class="post-description">
			<div class="placeholder w-full" />
			<div class="placeholder w-full" />
		</div>
		<div class="post-media placeholder" />
		<div class="post-like-panel flex row space">
			<div class="post-like-counter text-medium w-25">
				<div class="placeholder w-full" />
			</div>
			<div class="post-like-counter w-25">
				<div class="placeholder w-full" />
			</div>
		</div>
		<div class="post-comments w-full">
			<div class="placeholder w-full" />
			<div class="see-all w-50">
				<div class="placeholder w-full" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PlaceholderPost'
}
</script>
