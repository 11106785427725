import { http } from '@/http/index'

async function getPostsWithTotalConsumerLikes(consumerId, sort_by = 'likes') {
	const params = {
		sort_by
	}
	return http.get(`/consumers/${consumerId}/posts-which-liked`, { params }).then((response) => response.data)
}
async function getMorePostsWithTotalConsumerLikes(url, sort_by = 'likes') {
	const params = {
		sort_by
	}
	return http.get(url, { params }).then((response) => response.data)
}

export { getPostsWithTotalConsumerLikes, getMorePostsWithTotalConsumerLikes }
