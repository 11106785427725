<template>
	<dialog-component @close="closeDialog">
		<template #header-title>Требуется верификация.</template>
		<p class="status-dialog-text">
			Мы должны убедиться, что ты — настоящая. Приготовься сделать селфи с паспортом. После проверки тебе станет
			доступен просмотр чужих постов, а твои собственные посты опубликуются автоматически.
		</p>
		<ButtonComponent @click="startVerification"> Начать верификацию </ButtonComponent>
	</dialog-component>
</template>

<script>
import { mapActions } from 'pinia'
import { useModalStore } from '@/stores/modal'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'GirlVerificationNeedsDialog',
	components: { ButtonComponent, DialogComponent },
	methods: {
		...mapActions(useModalStore, ['openVerificationDialog']),
		startVerification() {
			this.$emit('close-dialog')
			this.openVerificationDialog()
		},
		closeDialog() {
			this.$emit('close-dialog')
		}
	}
}
</script>
