<template>
	<div class="loader">
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="#6A6A78"
			class="spinner rotating"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M40.9707 7.02958L37.2 10.8002C34.1464 7.74637 30.1285 5.84574 25.8307 5.42222C21.5329 4.99869 17.2212 6.07846 13.6302 8.47755C10.0393 10.8766 7.39131 14.4466 6.13744 18.5792C4.88357 22.7118 5.1014 27.1513 6.75381 31.1413C8.40623 35.1312 11.391 38.4248 15.1995 40.4609C19.0081 42.4969 23.4048 43.1494 27.6405 42.3071C31.8762 41.4649 35.6888 39.1801 38.4287 35.8419C41.1686 32.5038 42.6664 28.3189 42.6667 24.0002H48C48 29.5528 46.0747 34.9335 42.5521 39.2256C39.0296 43.5178 34.1278 46.4557 28.682 47.5389C23.2361 48.6221 17.5832 47.7835 12.6863 45.1661C7.78945 42.5486 3.95165 38.3142 1.82683 33.1843C-0.297991 28.0544 -0.578363 22.3465 1.03349 17.033C2.64534 11.7196 6.04968 7.12946 10.6665 4.04468C15.2832 0.959891 20.8268 -0.428659 26.3526 0.115619C31.8784 0.659897 37.0445 3.10333 40.9707 7.02958Z"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'Loader'
}
</script>

<style>
.loader {
	text-align: center;
	width: 100%;
	margin: 0 auto;
	grid-area: auto / span 3;
}
</style>
