import { http } from '@/http/index'

async function getLikes(girlId) {
	return http.get(`/girls/${girlId}/posts-with-likes`).then((response) => response.data)
}

async function getMoreLikes(url) {
	return http.get(url).then((response) => response.data)
}

export { getLikes, getMoreLikes }
