import { defineStore } from 'pinia'
import tutorialApi from '@/http/girl/tutorials'

export const useTutorialStore = defineStore('tutorial', {
	state: () => ({
		tutorialSlides: [],
		currentTutorialName: null
	}),
	getters: {
		showTutorialSlider: (state) => state.tutorialSlides.length > 0
	},
	actions: {
		async startTutorial(name) {
			if (this.currentTutorialName === name) {
				return null
			}

			this.currentTutorialName = name

			try {
				this.tutorialSlides = await tutorialApi.getSliderByName(name)
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
			return null
		},
		async finishTutorial(name = this.currentTutorialName) {
			this.tutorialSlides = []
			try {
				await tutorialApi.saveCompletedSlider(name)
				return name
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
			return null
		}
	}
})
