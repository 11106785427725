<template>
	<p
		:class="['notification-sidebar', { 'notification-sidebar-with-count': count, 'notification-sidebar-red': !count }]"
	>
		<span v-if="count"> {{ count }}</span>
	</p>
</template>

<script>
export default {
	name: 'NotificationSidebar',
	props: {
		count: {
			type: Number,
			default: null
		}
	}
}
</script>

<style lang="scss" scoped>
.notification-sidebar {
	position: absolute;
	right: 8px;
	&-red {
		background-image: url('@/assets/images/icons/notification-red.svg');
		background-repeat: no-repeat;
		width: 24px;
		height: 19px;
	}
	&-with-count {
		background-color: $color-blue-dark;
		min-width: 23px;
		min-height: 20px;
		border-radius: 100px;
		color: $color-white;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		padding-left: 8px;
		padding-right: 8px;
		display: flex;
		justify-content: center;
	}
}
</style>
