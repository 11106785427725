export class RefreshProfileClass {
	constructor(store, t) {
		if (RefreshProfileClass._instance) {
			// eslint-disable-next-line no-constructor-return
			return RefreshProfileClass._instance
		}
		RefreshProfileClass._instance = this

		this.store = store
		this.t = t
		this.interval = null
	}

	startInterval() {
		this.interval = setInterval(() => {
			if (!this.store.isProfileLoaded) {
				clearInterval(this.interval)
				return
			}
			this.store.getProfile()
		}, this.t)
	}

	stopInterval() {
		clearInterval(this.interval)
		this.interval = null
	}

	getInterval() {
		return this.interval
	}

	refreshProfile(profile) {
		this.stopInterval()
		this.store = profile
	}
}
