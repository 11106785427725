import { http } from '@/http/index'

async function getNotifications() {
	return http.get('/consumer/notifications').then((response) => response.data)
}

async function getMoreNotifications(url) {
	return http.get(url).then((response) => response.data)
}

export { getNotifications, getMoreNotifications }
