<template functional>
	<img
		class="upload-image-preview"
		v-show="props.src"
		:src="props.src"
		alt=""
		v-on="listeners.load ? { load: listeners.load } : null"
		ref="previewImg"
	/>
</template>

<script>
export default {
	name: 'UploadImagePreview',
	props: {
		src: { type: String, default: '' }
	}
}
</script>

<style lang="scss">
.upload-image-preview {
	width: 100%;
	height: auto;
	margin: auto;
	display: flex;
	border-radius: 12px;
	@media (min-width: $screen-desktop) {
		border-radius: 0;
	}
}
</style>
