import { http } from '../index'

function getConsumersLists(params) {
	return http.get('/girl/consumers-lists', { params }).then((res) => res.data)
}

function createConsumersList(data) {
	return http.post('/girl/consumers-list', data).then((res) => res.data)
}

function updateConsumersList(id, data) {
	return http.put(`/girl/consumers-list/${id}`, data).then((res) => res.data)
}

function removeConsumersList(id) {
	return http.delete(`/girl/consumers-lists/${id}`).then((res) => res.data)
}

function getConsumers(params) {
	return http.get(`/girl/consumers-list/consumers/search`, { params }).then((res) => res.data)
}

function addConsumerToList(consumerId, girlListsId) {
	return http
		.post(`/girl/consumers-lists/consumers/${consumerId}/add`, { girl_lists_id: girlListsId })
		.then((res) => res.data)
}

export {
	getConsumersLists,
	createConsumersList,
	updateConsumersList,
	removeConsumersList,
	getConsumers,
	addConsumerToList
}
