import { http } from '@/http/index'

async function getFavoriteComments(girlId) {
	return http.get(`/girls/${girlId}/favorite-comments`).then((response) => response.data)
}

async function getMoreFavoriteComments(url) {
	return http.get(url).then((response) => response.data)
}

export { getFavoriteComments, getMoreFavoriteComments }
