import { defineStore } from 'pinia'
import girlApi from '@/http/girl/index'
import { getMoreData } from '@/stores/helper'

export const useReferralStore = defineStore('referral', {
	state: () => ({
		referrals: {},
		isLoading: false,
		link: null,
		linkId: null,
		greeting: null,
		referrerShare: null,
		lifetimeInDays: null
	}),
	actions: {
		async getLink() {
			const linkInfo = await girlApi.getReferralLink()
			this.link = linkInfo.link
			this.greeting = linkInfo.greeting
			this.referrerShare = linkInfo.referrer_share
			this.lifetimeInDays = linkInfo.lifetime_in_days
			if (this.link) {
				this.linkId = linkInfo.link.slice(-8)
			}
		},
		async changeGreetings(link, data) {
			const linkInfo = await girlApi.changeReferralGreeting(link, { greeting: data })
			this.link = linkInfo.link
			this.greeting = linkInfo.greeting
		},
		async getReferrals(orderBy = '', desc = false) {
			this.referrals = []
			try {
				this.isLoading = true
				this.referrals = await girlApi.getReferrals(orderBy, desc)
			} finally {
				this.isLoading = false
			}
		},
		async getMoreReferrals() {
			await getMoreData(this.referrals, girlApi.getMoreReferrals)
		}
	}
})
