import { http } from '@/http/index'

async function getAwards(consumerId) {
	return http.get(`/consumers/${consumerId}/awards`).then((response) => response.data)
}

async function getMoreAwards(url) {
	return http.get(url).then((response) => response.data)
}

export { getAwards, getMoreAwards }
