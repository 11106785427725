const routes = [
	{
		path: '/consumer/:id',
		component: () => import('@/views/Consumer/IndexId.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '/consumer/notifications',
				name: 'consumer-notifications-id',
				component: () => import('@/views/Consumer/NotificationsView.vue'),
				meta: {
					title: 'Уведомления'
				}
			},
			{
				path: '',
				component: () => import('@/views/Consumer/ProfileView.vue'),
				children: [
					{
						path: '',
						name: 'consumer-profile-id',
						meta: {
							title: 'Профиль',
							auth: true
						},
						component: () => import('@/views/Consumer/AwardThumbsView.vue')
					},
					{
						path: 'comments',
						name: 'consumer-reactions-id',
						component: () => import('@/views/Consumer/CommentsView.vue')
					},
					{
						path: 'likes',
						name: 'consumer-likes-id',
						component: () => import('@/views/Consumer/LikedPostsThumbsView.vue')
					}
				]
			},
			{
				path: 'award-posts',
				name: 'award-posts-id',
				component: () => import('@/views/Consumer/AwardPostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Награды'
				}
			},
			{
				path: 'liked-posts',
				name: 'liked-posts-id',
				component: () => import('@/views/Consumer/LikedPostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Лайки'
				}
			},
			{
				path: 'girls-with-total-likes-by-posts',
				name: 'consumer-girls-with-total-likes-by-posts-id',
				component: () => import('@/views/Consumer/GirlsWithTotalLikesByPostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'подписки'
				}
			},
			{
				path: 'posts-with-total-consumer-likes',
				name: 'posts-with-total-consumer-likes-id',
				component: () => import('@/views/Consumer/PostsWithTotalConsumerLikes.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Лайки к постам'
				}
			}
		]
	},
	{
		path: '/f/:nickname',
		component: () => import('@/views/Consumer/Index.vue'),
		children: [
			{
				path: '/f/notifications',
				name: 'consumer-notifications',
				component: () => import('@/views/Consumer/NotificationsView.vue'),
				meta: {
					title: 'Уведомления'
				}
			},
			{
				path: '/consumer/subscriptions',
				name: 'consumer-subscriptions',
				component: () => import('@/views/Consumer/SubscriptionsView.vue'),
				meta: {
					layout: 'arrow-back',
					title: 'Мои подписки'
				}
			},

			{
				path: '/consumer/profile/edit',
				name: 'consumer-edit',
				component: () => import('@/views/Consumer/ProfileEditView.vue'),
				meta: {
					layout: 'empty'
				}
			},
			{
				path: '',
				component: () => import('@/views/Consumer/ProfileView.vue'),
				children: [
					{
						path: '',
						name: 'consumer-profile',
						meta: {
							title: 'Профиль'
						},
						component: () => import('@/views/Consumer/AwardThumbsView.vue')
					},
					{
						path: 'comments',
						name: 'consumer-reactions',
						component: () => import('@/views/Consumer/CommentsView.vue')
					},
					{
						path: 'likes',
						name: 'consumer-likes',
						component: () => import('@/views/Consumer/LikedPostsThumbsView.vue')
					}
				]
			},
			{
				path: 'girls-with-total-likes-by-posts',
				name: 'consumer-girls-with-total-likes-by-posts',
				component: () => import('@/views/Consumer/GirlsWithTotalLikesByPostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Подписки'
				}
			},
			{
				path: 'posts-with-total-consumer-likes',
				name: 'posts-with-total-consumer-likes',
				component: () => import('@/views/Consumer/PostsWithTotalConsumerLikes.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Лайки к постам'
				}
			},
			{
				path: 'award-posts',
				name: 'award-posts',
				component: () => import('@/views/Consumer/AwardPostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Награды'
				}
			},
			{
				path: 'liked-posts',
				name: 'liked-posts',
				component: () => import('@/views/Consumer/LikedPostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Лайки'
				}
			}
		]
	},
	{
		path: '/receipts',
		name: 'receipts',
		component: () => import('@/views/Consumer/ReceiptsView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Электронные чеки'
		}
	},
	{
		path: '/receipt/:receiptLink',
		name: 'receipt',
		component: () => import('@/views/ReceiptView.vue'),
		meta: {
			layout: 'arrowBack',
			title: 'Чек'
		}
	},
	{
		path: '/subswait',
		name: 'subswait',
		component: () => import('@/views/SubswaitView.vue'),
		meta: {
			auth: undefined,
			layout: 'empty'
		}
	},
	{
		path: '/girl-mobile-subswait',
		name: 'girl-mobile-subswait',
		component: () => import('@/views/Consumer/GirlMobileSubscriptionWaitingView.vue'),
		props: true,
		meta: {
			layout: 'logoOnly',
			auth: true
		}
	},
	{
		path: '/login-if-has-any-subscription-to-girl',
		name: 'login-if-has-any-subscription-to-girl',
		component: () => import('@/views/LoginIfHasAnySubscriptionToGirlView.vue'),
		meta: {
			auth: undefined,
			layout: 'empty'
		}
	},
	{
		path: '/sign-in-as-test-consumer',
		name: 'sign-in-as-test-consumer',
		component: () => import('@/views/Consumer/SignInAsTestConsumer.vue'),
		meta: {
			auth: undefined,
			layout: 'empty'
		}
	},
	{
		path: '/',
		name: 'error-subscription-waiting-only-one-girl',
		component: () => import('@/views/Consumer/ErrorSubscriptionWaitingView.vue'),
		props: true,
		meta: {
			layout: 'logoOnly',
			auth: true
		}
	},
	{
		path: '/',
		name: 'error-subscription-failed-only-one-girl',
		component: () => import('@/views/Consumer/ErrorSubscriptionFailedView.vue'),
		props: true,
		meta: {
			layout: 'logoOnly',
			auth: true
		}
	},
	{
		path: '/error-subscribe',
		name: 'error-subscribe',
		component: () => import('@/views/ErrorSubscribeView.vue'),
		props: true,
		meta: {
			layout: 'logoOnly',
			hideSidebar: true,
			fullWidthDesktop: true
		}
	},
	{
		path: '/forbidden',
		name: 'forbidden ',
		component: () => import('@/views/ErrorView.vue'),
		props: true,
		meta: {
			layout: 'logoOnly',
			hideSidebar: true,
			fullWidthDesktop: true,
			code: 403
		}
	}
]

export default routes
