import { defineStore } from 'pinia'
import consumerTutorialApi from '@/http/consumer/tutorial'

export const useConsumerTutorialStore = defineStore('consumerTutorial', {
	state: () => ({
		consumerTutorial: null
	}),
	actions: {
		setTutorial(data) {
			this.consumerTutorial = data
		},
		async finishTutorial(name) {
			try {
				await consumerTutorialApi.finishTutorial(name)
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
		}
	}
})
