<template>
	<div :class="['logo', { 'hide-mobile': hideMobileLogo }]" @click="resetFilterAndScroll">
		<img :src="logoUrl" alt="" />
	</div>
</template>

<script>
import { mapActions } from 'pinia'
import { useHomeStore } from '@/stores/home'
import logo from '@/assets/images/logo.png'

export default {
	name: 'HeaderLogo',
	data() {
		return {
			logoUrl: import.meta.env.VITE_LOGO_URL || logo
		}
	},
	computed: {
		hideMobileLogo() {
			return ['balanceOnly', 'balanceWithMenu'].includes(this.$route.meta.layout)
		}
	},
	methods: {
		...mapActions(useHomeStore, ['resetFilter']),
		resetFilterAndScroll() {
			if (this.$route.name === 'home') {
				this.resetFilter()
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})
			} else {
				this.$router.push({ name: 'home' })
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.logo {
	width: auto;
	height: 37px;
	cursor: pointer;
	transition: $transition-opacity;
	&:hover {
		opacity: 0.7;
	}
	@media (max-width: $screen-desktop) {
		height: 32px;
	}
}

.logo img {
	height: 100%;
}
</style>
