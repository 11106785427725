import { http } from '@/http/index'

async function getTwoDaysStatistics() {
	return http.get('/girl/two-days-statistics').then((response) => response.data)
}

async function getStatistics(datesRange) {
	const params = {
		date_range: datesRange
	}
	return http.get('/girl/statistics', { params }).then((response) => response.data.data)
}

async function getStatisticFile(datesRange) {
	const params = {
		date_range: datesRange
	}
	return http.get('/girl/export-statistics-history', { params, responseType: 'blob' }).then((response) => response.data)
}

export { getTwoDaysStatistics, getStatistics, getStatisticFile }
