const routes = [
	{
		path: '',
		component: () => import('@/views/Girl/Auth/AuthView.vue'),
		meta: {
			auth: false
		},
		children: [
			{
				path: 'i/:referralCode',
				name: 'referral-redirect',
				component: () => import('@/views/ReferralRedirect.vue'),
				meta: { layout: 'logoOnly' }
			},
			{
				path: '',
				component: () => import('@/views/Girl/Auth/AuthFormView.vue'),
				children: [
					{
						path: 'author/login',
						name: 'author-login',
						component: () => import('@/views/Girl/Auth/LoginView.vue'),
						meta: { layout: 'logoOnly', hideSidebar: true, fullWidthDesktop: true }
					},
					{
						path: 'author/registration/:referralCode?',
						name: 'author-registration',
						component: () => import('@/views/Girl/Auth/RegistrationView.vue'),
						meta: { layout: 'logoOnly', hideSidebar: true },
						beforeEnter: (to, from, next) => {
							if (import.meta.env.VITE_DISABLED_REG_FOR_AUTHOR === 'true') {
								next({ path: '/' })
							}
							next()
						}
					}
				]
			},
			{
				path: 'author/confirm-email',
				name: 'author-confirm-email',
				component: () => import('@/views/Girl/Auth/ConfirmEmailView.vue'),
				meta: { layout: 'logoOnly' }
			},
			{
				path: 'author/forgot-password',
				name: 'author-forgot-password',
				component: () => import('@/views/Girl/Auth/ForgotPasswordView.vue'),
				meta: { layout: 'logoOnly' }
			},
			{
				path: 'author/reset-password',
				name: 'author-reset-password',
				component: () => import('@/views/Girl/Auth/ResetPasswordView.vue'),
				meta: { layout: 'logoOnly' }
			},
			{
				path: 'author/reset-link-has-sent',
				name: 'author-reset-link-has-sent',
				component: () => import('@/views/Girl/Auth/ResetLinkHasSent.vue'),
				meta: { layout: 'logoOnly' }
			},
			{
				path: 'author/email-verification',
				name: 'author-email-verification',
				component: () => import('@/views/Girl/Auth/EmailVerificationView.vue'),
				meta: { layout: 'empty', auth: undefined }
			},
			{
				path: 'author/email-verification-link-expired',
				name: 'author-email-verification-link-expired',
				component: () => import('@/views/Girl/Auth/EmailVerificationExpiredView.vue'),
				meta: { layout: 'logoOnly', auth: undefined }
			},
			{
				path: '/girl/profile-delete-link-expired',
				name: 'profile-delete-link-expired',
				component: () => import('@/views/Girl/Auth/ProfileDelete.vue'),
				meta: { layout: 'logoOnly', auth: undefined },
				props: { status: 'expired' }
			},
			{
				path: '/girl/profile-deleted',
				name: 'profile-deleted',
				component: () => import('@/views/Girl/Auth/ProfileDelete.vue'),
				meta: { layout: 'logoOnly', auth: undefined },
				props: { status: 'deleted' }
			}
		]
	}
]

export default routes
